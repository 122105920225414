import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';
import getLatinized from '../lib/getLatinized';

const getPrepared = items => (
    items
        .filter((x, i, a) => i === a.findIndex(y =>
            x.firstName === y.firstName &&
            x.lastName === y.lastName
        ))
        .sort((a, b) =>
            getLatinized((a.lastName + a.firstName).toLowerCase()) >
            getLatinized((b.lastName + b.firstName).toLowerCase()) ? 1 : -1
        )
        .reduce((acc, cur) => {
            if (!acc.length || acc[acc.length - 1].incipit !== getLatinized(cur.lastName[0])) {
                acc.push({
                    incipit: getLatinized(cur.lastName[0]),
                    subitems: [cur],
                });
            } else {
                acc[acc.length - 1].subitems.push(cur);
            }
            return acc;
        }, [])
);

const Authors = ({
    pageContext: {titleDictionaryIndex, items, description, from, keywords, lang, title, url},
}) => (
    <Layout
        description={description}
        keywords={keywords}
        lang={lang}
        name='authors'
        path={from}
        title={title}
        url={url}
    >
        {titleDictionaryIndex && <h2>{dictionary[titleDictionaryIndex][lang]}</h2>}
        {getPrepared(items).map((item, i) => (
            <div key={i}>
                <h3>{item.incipit}</h3>
                <ul>
                    {item.subitems.map((subitem, subi) => (
                        <li key={subi}>
                            {subitem.linked ? (
                                <Link to={`${lang}/search-by-author?query=${subitem.firstName} ${subitem.lastName}`}>
                                    {subitem.firstName} {subitem.lastName}
                                </Link>
                            ) : (
                                `${subitem.firstName} ${subitem.lastName}`
                            )}
                            {subitem.affiliation[lang] && `, ${subitem.affiliation[lang]}`}
                        </li>
                    ))}
                </ul>
            </div>
        ))}
    </Layout>
);

export default Authors;
